import React from "react"
import { Helmet } from "react-helmet"

import { Layout } from "../../components/Layout"
import { Form } from "../../components/email/Form"

export default function EmailPage() {
  return (
    <Layout noHeader hasCustomFooter>
      <Helmet defer>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>
      <Form frame />
    </Layout>
  )
}
